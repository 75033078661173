import React from 'react';

import Layout from '../components/Layout';

export default function NotFoundPage() {
  return (
    <Layout title="Nicht gefunden">
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Nicht gefunden</h1>
            <p>
              Die von Ihnen angeforderte Resource gibt es nicht mehr, oder hat
              es nie gegeben.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}
